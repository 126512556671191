<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div id="sekaci-plan-change-quantity-app">
      <span>{{ total_pairs }}</span>
      <v-dialog v-model="dialog" persistent max-width="70%" eager attach="body">
        <template v-slot:activator="{ on }">
          <v-btn class="ml-2"
                 color="indigo"
                 x-small
                 icon
                 outlined
                 v-on="on"
                 @click="refreshForm"
          >
            <v-icon small>edit</v-icon>
          </v-btn>
        </template>
        <DjangoForm
            ref="djangoForm"
            :formUrl="`/workflow/manufacturing/restsekaciplanchangequantity/${sekaci_plan_id}/start/`"
            :refreshManually="true"
            @done="refresh()"
            @close="dialog = false"
        />
      </v-dialog>
    </div>
<!--  </div>-->
</template>

<script>
import DjangoForm from "../DjangoForm";

export default {
  name: 'SekaciPlanChangeQuantity',
  elClass: 'sekaci-plan-change-quantity-app',
  requiredPermission: 'manufacturing.can_start_sekaciplanprocesschangequantities',
  components: {DjangoForm},
  data() {
    return {
      dialog: false,
      updating: false,
      sekaci_plan_process_id: '',
      sekaci_plan_id: '',
      total_pairs: 0,
    }
  },
  async mounted() {
    const elem = this.$el.parentElement.lastElementChild;
    this.sekaci_plan_process_id = elem.dataset.sekaciPlanProcessId;
    const {sekaci_plan} = await this.$api.getSekaciPlan(this.sekaci_plan_process_id)
    this.total_pairs = sekaci_plan.total_pairs
    this.sekaci_plan_id = sekaci_plan.id
  },
  methods: {
    async save() {
      this.updating = true;
      const resolve = () => {
        this.dialog = false;
        this.refresh();
      };
      await this.form.call(resolve);
      this.updating = false;
    },
    async refreshForm() {
      return await this.$refs.djangoForm.refresh()
    },
    async refresh() {
      var reloadWithTurbolinks = (function () {
        var scrollPosition

        function reload() {
          scrollPosition = [window.scrollX, window.scrollY]
          // eslint-disable-next-line no-undef
          Turbolinks.visit(window.location.toString().slice(0, window.location.toString().length - 1), {action: 'replace'})
        }

        document.addEventListener('turbolinks:load', function () {
          if (scrollPosition) {
            window.scrollTo.apply(window, scrollPosition)
            scrollPosition = null
          }
        })

        return reload
      })()
      reloadWithTurbolinks()
    }
  },
}
</script>

<style scoped lang="less">
///deep/ .v-date-picker-table--date td {
//  padding: 0 !important;
//}
//
///deep/ .v-date-picker-table--date tr {
//  border-style: none !important;
//}
//
///deep/ .v-btn.v-btn--outline {
//  margin-top: 0;
//  margin-bottom: 0;
//}
</style>