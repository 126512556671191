import Vue from 'vue'
import Router from 'vue-router'
import store from './store.js'
import BaseView from "./views/BaseView";

Vue.use(Router);

let routers = [
    {
        path: 'permission-denied/',
        name: 'permission-denied',
        component: () => import(/* webpackChunkName: "about" */ './views/PermissionDenied.vue')

    }, {
        path: 'wholesale-catalogue/',
        name: 'wholesale-catalogue',
        meta: {required_attribute: 'can_shop', redirectTo: '/wholesale-catalogue-request-access/'},
        component: () => import(/* webpackChunkName: "about" */ './views/WholesaleCatalogue.vue')
    }, {
        path: 'model/tags/',
        name: 'productmodel-tags',
        meta: {required_permission: 'base.view_tag'},
        component: () => import(/* webpackChunkName: "about" */ './views/ProductModelTags.vue')
    }, {
        path: 'order/dispatch/',
        name: 'dispatch-list',
        meta: {required_permission: 'order.view_orderitem'},
        component: () => import(/* webpackChunkName: "about" */ './views/DispatchList.vue')
    }, {
        path: 'order/dispatch/:id/detail/',
        name: 'dispatch-detail',
        meta: {required_permission: 'order.view_orderitem'},
        component: () => import(/* webpackChunkName: "about" */ './views/DispatchDetail.vue')
    }, {
        path: 'car/',
        name: 'car-list',
        meta: {required_permission: 'wholesale.view_car'},
        component: () => import(/* webpackChunkName: "about" */ './views/CarList.vue')
    }, {
        path: 'car/:id/detail/',
        name: 'car-detail',
        meta: {required_permission: 'wholesale.view_car'},
        component: () => import(/* webpackChunkName: "about" */ './views/CarDetail.vue')
    }, {
        path: 'car/:id/print/',
        name: 'car-print',
        meta: {required_permission: 'wholesale.view_car'},
        component: () => import(/* webpackChunkName: "about" */ './views/CarPrint.vue')
    }
    , {
        path: 'car/:id/prepare/',
        name: 'car-prepare',
        meta: {required_permission: 'wholesale.prepare_car'},
        component: () => import(/* webpackChunkName: "about" */ './views/CarPrepare.vue')
    }, {
        path: 'not_dispatched/',
        name: 'user-not-dispatch-list',
        component: () => import(/* webpackChunkName: "about" */ './views/UserNotDispatchedList.vue')
    },
    {
        path: 'manufacturing/schedule/',
        name: 'manufacturing-schedule',
        meta: {required_permission: 'manufacturing.view_carouselsetup'},
        component: () => import(/* webpackChunkName: "about" */ './views/manufacturing/App.vue')
    }, {
        path: 'reservations/',
        name: 'reservations',
        meta: {required_attribute: 'can_shop', redirectTo: '/wholesale-catalogue-request-access/'},
        component: () => import(/* webpackChunkName: "about" */ './views/ProductionReservations.vue')
    },{
        path: 'materials/',
        name: 'materials',
        // meta: {required_permission: '', redirectTo: '/'},
        component: () => import(/* webpackChunkName: "about" */ './views/SekaciPlanMaterials')
    },

    // {
    //     path: 'manufacturing/schedule/planhelper',
    //     name: 'manufacturing-schedule-planhelper',
    //     meta: {required_permission: 'manufacturing.view_carouselsetup'},
    //     component: () => import(/* webpackChunkName: "about" */ './views/manufacturing/PlanHelper.vue')
    // }
];

let vue_only_routers = [
    {
        path: 'manufacturing/schedule/planned',
        name: 'manufacturing-schedule-planned',
        // meta: {required_permission: 'manufacturing.update_operation_sekaci_plan'},
        component: () => import(/* webpackChunkName: "about" */ './views/manufacturing/PlannedSchedule.vue')
    }, {
        path: 'orders/samples',
        name: 'order-samples',
        // meta: {required_permission: 'order.view_order'},
        component: () => import(/* webpackChunkName: "about" */ './views/orders/SamplesLookup.vue')
    }, {
        path: 'base/price-calculation/',
        name: 'product-price-calculation',
        meta: {required_permission: 'base.view_product'},
        component: () => import(/* webpackChunkName: "about" */ './views/base/ProductPriceCalculation.vue')
    }, {
        path: 'reservations/',
        name: 'vreservations',
        meta: {required_attribute: 'can_shop', redirectTo: '/wholesale-catalogue-request-access/'},
        component: () => import(/* webpackChunkName: "about" */ './views/ProductionReservations.vue')
    }, {
        path: 'materials/',
        name: 'vmaterials',
        // meta: {required_permission: '', redirectTo: '/'},
        component: () => import(/* webpackChunkName: "about" */ './views/SekaciPlanMaterials')
    }, {
        path: 'wholesale-catalogue/',
        name: 'wholesale-catalogue',
        meta: {required_attribute: 'can_shop', redirectTo: '/wholesale-catalogue-request-access/'},
        component: () => import(/* webpackChunkName: "about" */ './views/WholesaleCatalogue.vue')
    },
]

const router = new Router({
    mode: 'history',
    routes: [
        {path: '/v', component: BaseView, children: routers},
        {path: '/vue', component: BaseView, children: vue_only_routers}
    ]
});


router.beforeEach((to, from, next) => {
    const checkAuth = () => {
        const {user} = store.state;
        // window.dispatchEvent(new CustomEvent('vueInitialized')); // always ends up here
        // Guard logic
        const perm = to.meta.required_permission;
        const group = to.meta.required_group;
        const attr = to.meta.required_attribute;
        const redirectTo = to.meta.redirectTo;
        if (perm === undefined && group === undefined && attr === undefined) {
            return next()
        } else {
            if (perm !== undefined && user.permissions.indexOf(perm) === -1)
                next({name: 'permission-denied'});
            if (group !== undefined && user.groups.indexOf(group) === -1)
                next({name: 'permission-denied'});
            if (attr !== undefined && !user[attr]) {
                // eslint-disable-next-line
                if (redirectTo !== undefined) return Turbolinks.visit(`${redirectTo}?next=${to.fullPath}`);
                next({name: 'permission-denied'});
            }

        }
        return next();
    };

    if (!store.state.initialized) {
        store.dispatch('initialize').then(() => {
            checkAuth();
        });
    } else {
        checkAuth();
    }
});

export default router


