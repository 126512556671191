<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div id="vue-basket-app">
    <confirm ref="confirm"></confirm>
    <v-menu :close-on-content-click="false"
            :nudge-width="600"
            :nudge-top="-5"
            transition="slide-y-transition"
            offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
            class="vue-basket ma-0 pa-0"
            v-on="on"
            dark
            text
            small
            color="transparent"
            :disabled="basketCount === 0"
        >
          <v-badge color="rgb(1, 87, 155)" :content="basketCount" :value="basketCount" offset-y="8" offset-x="6">
            <v-icon>
              shopping_cart
            </v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-card tile color="white pa-4">
        <v-flex v-if="userIsStaff()">
          <v-btn small icon text class="ma-1" color="red" @click="clearBasket">
            <v-icon>delete_forever</v-icon>
          </v-btn>
        </v-flex>

        <!--        <v-container class="overflow-y-auto">-->
        <v-list class="overflow-y-auto" style="max-height: 60vh">
          <v-row no-gutters v-for="item in basketItems" :key="item.id" style="border-bottom: thin solid"
                 class="pt-2 pb-2">
            <v-col cols="12" sm="6" md="5">
              <v-row no-gutters>
                <v-col class="flex-grow-0 flex-shrink-1">
                  <v-img :src="item.img_url" width="60"></v-img>
                </v-col>
                <v-col class="flex-grow-1 flex-shrink-0 pl-1">
                  <a :href="getUrl(item.model)" class="subheading">{{ item.model }}</a>
                  <div class="v-list__tile__sub-title text-truncate basket-product-color">
                    {{ item.color }}
                  </div>
                </v-col>
                <v-col align="right" align-self="center" class="pr-3 flex-grow-0 flex-shrink-1">
                  {{ price(item) }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="7">
              <WholesaleCatalogueProductCardAssortment
                  :product="item"
                  :displayButtons="true"
                  :displayAllRows="true"
                  :displayOnlyPopulatedAssortments="true"
                  :basket="true"
              ></WholesaleCatalogueProductCardAssortment>
            </v-col>
          </v-row>
        </v-list>
        <!--        </v-container>-->


        <!--        <v-list style="max-height: 60vh" class="scroll-y">-->
        <!--          <template v-for="(item, index) in basketItems">-->
        <!--            <v-flex xs12 :key="index" class="px-3 py-1">-->
        <!--              <v-layout row wrap class="mb-0" justify-center align-center>-->
        <!--                <v-flex shrink class="pr-3">-->
        <!--                  <v-img :src="item.img_url" width="60"></v-img>-->
        <!--                </v-flex>-->
        <!--                <v-flex grow>-->
        <!--                  <a :href="getUrl(item.model)" class="subheading">{{ item.model }}</a>-->
        <!--                  <div class="v-list__tile__sub-title text-truncate basket-product-color"-->
        <!--                       style="max-width: 120px">-->
        <!--                    {{ item.color }}-->
        <!--                  </div>-->
        <!--                </v-flex>-->
        <!--                <v-flex shrink class="text-right pr-4 subheading">-->
        <!--                  <span>{{ price(item) }}</span>-->
        <!--                </v-flex>-->
        <!--                <v-flex xs12 md6>-->
        <!--                  <WholesaleCatalogueProductCardAssortment-->
        <!--                      :product="item"-->
        <!--                      :displayButtons="true"-->
        <!--                      :displayAllRows="true"-->
        <!--                      :displayOnlyPopulatedAssortments="true"-->
        <!--                      :basket="true"-->
        <!--                  ></WholesaleCatalogueProductCardAssortment>-->
        <!--                </v-flex>-->
        <!--              </v-layout>-->
        <!--            </v-flex>-->
        <!--            <v-divider v-if="index + 1 < basketItems.length" :key="`divider-${index}`"></v-divider>-->
        <!--          </template>-->
        <!--        </v-list>-->
        <v-layout align-center py-2 wrap>
          <v-flex xs12 py-2>
            <v-layout align-end wrap text-right>
              <v-flex xs6 sm7 lg9 class="grey-text">{{ $translate('Total Pairs') }}:</v-flex>
              <v-flex xs6 sm5 lg3>{{ basket.total_pairs }}p</v-flex>
              <v-flex xs6 sm7 lg9 class="subheading grey-text">{{ $translate('Subtotal') }}:</v-flex>
              <v-flex xs6 sm5 lg3 class="title">{{ basket.subtotal }}</v-flex>
              <template v-if="basket.discount_rate > 0">
                <v-flex xs6 sm7 lg9 class="subheading grey-text">
                  {{ $translate('Discount') }} ({{ basket.discount_rate * 100 }}%):
                </v-flex>
                <v-flex xs6 sm5 lg3 class="subheading">-{{ basket.discounted_amount }}</v-flex>
                <v-divider class="flex xs6 sm5 offset-sm7 offset-xs6 lg3 offset-lg9"></v-divider>
                <v-flex xs6 sm7 lg9 class="subheading grey-text">{{ $translate('Total') }}:</v-flex>
                <v-flex xs6 sm5 lg3 class="title">{{ basket.subtotal_incl_discount }}</v-flex>
              </template>
              <v-flex xs6 sm7 lg9 class="subheading grey-text">{{ $translate('VAT') }} (20%):</v-flex>
              <v-flex xs6 sm5 lg3 class="subheading">+{{ basket.total_tax }}</v-flex>
              <v-divider class="flex xs6 sm5 offset-sm7 offset-xs6 lg3 offset-lg9"></v-divider>
              <v-divider class="flex xs6 sm5 offset-sm7 offset-xs6 lg3 offset-lg9"></v-divider>
              <v-flex xs6 sm7 lg9 class="subheading grey-text">{{ $translate('Grand Total') }}:</v-flex>
              <v-flex xs6 sm5 lg3 class="headline green-text darken-1">{{ basket.grand_total }}</v-flex>
            </v-layout>
          </v-flex>


          <v-flex xs12>
            <v-btn block large color="primary"
                   href="/generate_wholesale_order/"
                   :loading="basketConfirmed"
                   :disabled="basketConfirmed" @click="basketConfirmed = true">
              {{ $translate('Confirm Basket') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import WholesaleCatalogueProductCardAssortment from './components/WholesaleCatalogueProductCardAssortment';
import confirm from './components/Confirm';

export default {
  name: 'BasketApp',
  data() {
    return {
      basketConfirmed: false,
    }
  },
  components: {WholesaleCatalogueProductCardAssortment, confirm},
  async mounted() {
    // eslint-disable-next-line no-unused-vars
    await this.$store.dispatch('loadAssortments').then(resp => {
      this.assortments = this.$store.state.assortments;
    });
    // eslint-disable-next-line no-unused-vars
    this.$store.dispatch('loadBasket').then(r => {
    });
  },
  computed: {
    basket() {
      return this.$store.state.basket;
    },
    basketItems() {
      return this.$store.getters.getBasketItems;
    },
    basketCount() {
      return Object.keys(this.basketItems).length;
    },
    basketButtonColor() {
      return this.basketCount !== 0
    }
  },
  methods: {
    getUrl(model) {
      return `/v/wholesale-catalogue/?model=${model}`
    },
    price(product) {
      const currencyFormatter = new Intl.NumberFormat(this.$getUser().language, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      });
      return currencyFormatter.format(product.price).replace(/\s/g, '');
    },
    original_price(product) {
      const currencyFormatter = new Intl.NumberFormat(this.$getUser().language, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      });
      return currencyFormatter.format(product.original_price).replace(/\s/g, '');
    },
    userIsStaff() {
      const staff = this.$store.state.user.status === 'staff';
      const superuser = this.$store.state.user.status === 'superuser';
      return staff || superuser;
    },
    async clearBasket() {
      if (await this.$refs.confirm.open('Zmazať', 'Odstrániť všetky položky z košíka??', {
        color: 'red',
        yesText: 'Áno',
        noText: 'Nie',
      })) {
        this.$store.dispatch({
          type: 'flushBasket',
        });
      }
    }
  }
}
</script>

<style scoped lang="less">

#vue-basket-app {
  height: 54px !important;
}

///deep/ .application--wrap {
//  min-height: auto !important;
//}
//
///deep/ .theme--light {
//  background-color: inherit !important;
//}
//
/deep/ .vue-basket {
  //margin-top: 0 !important;
  //margin-bottom: 0 !important;
  top: -1px;
  height: 54px !important;
  width: 54px !important;
  //min-width: 60px !important;

  /deep/ button:focus {
    background-color: unset;
  }

  .v-btn__content {
    height: auto !important;
  }

  i {
    height: auto !important;
  }
}

//
/deep/ .vue-basket:focus {
  background-color: transparent !important;
  opacity: unset !important;
}

//
///deep/ .v-badge__badge {
//  line-height: 22px !important;
//
//  > span {
//    line-height: 22px !important;
//    font-size: 11px !important;
//  }
//}
//
///deep/ .v-badge {
//  height: 24px !important;
//  line-height: 24px !important;
//
//  > i {
//    height: 24px !important;
//    line-height: 24px !important;
//  }
//}
//
///deep/ .v-image {
//  border-radius: inherit !important;
//  height: 45px !important;
//  width: 60px !important;
//}
//
///deep/ .v-list__tile {
//  height: auto !important;
//}
//
///*/deep/ .v-list__tile__content {*/
///*    max-width: 70px;*/
///*}*/
///deep/ .v-list__tile__avatar {
//  width: 40px !important;
//}


/*@media (max-width: 600px) {*/
/*    .basket-product-color {*/
/*        max-width: 100px;*/
/*    }*/
/*}*/

/*@media (max-width: 450px) {*/
/*    .basket-product-color {*/
/*        max-width: 50px;*/
/*    }*/
/*}*/
</style>