import Api from '@/utils/api.js';

const api = new Api();

class Field {
    constructor(defaultValue) {
        if (defaultValue === undefined) {
            this.defaultValue = '';
        } else {
            this.defaultValue = defaultValue;
        }
        this.value = this.defaultValue;
        this.errors = [];
    }

    submitValue() {
        return this.value;
    }
}

export class DecimalField extends Field {
    static _parseLocaleNumber(stringNumber) {
        // var thousandSeparator = (1111).toLocaleString().replace(/1/g, '');
        // var decimalSeparator = (1.1).toLocaleString().replace(/1/g, '');
        // // .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        // //                 .replace(new RegExp('\\' + decimalSeparator), '.')
        return parseFloat(stringNumber
            // eslint-disable-next-line no-useless-escape
            .replace(new RegExp('\,'), '.')
        );
    }

    submitValue() {
        // can be locale aware (ie. comma as a decimal separator)
        return DecimalField._parseLocaleNumber(this.value);
    }
}

export class CharField extends Field {
}

export class IntegerField extends Field {
}

export class ListField extends Field {
}

export class DateField extends Field {
}


export class Form {
    constructor(apiUrl, fields) {
        this.apiUrl = apiUrl;
        this.fields = fields;
    }

    // eslint-disable-next-line no-unused-vars
    _defaultResolve(r) {
        this.resetValues();
    }

    _defaultReject(e) {
        let fields = this.fields;
        for (const [field, error] of Object.entries(e.response.data)) {
            if (field in fields) {
                fields[field].errors = error;
            }
        }
    }

    _getPayload() {
        let payload = {};
        for (const [name, field] of Object.entries(this.fields)) {
            payload[name] = field.submitValue();
        }
        return payload;
    }

    call(resolve, reject) {
        if (resolve === undefined) {
            resolve = r => this._defaultResolve(r);
        }
        if (reject === undefined) {
            reject = e => this._defaultReject(e);
        }

        this.resetErrors();

        let payload = this._getPayload();
        return api
            .post(this.apiUrl, payload)
            .then(resolve)
            .catch(reject);
    }

    resetErrors() {
        Object.values(this.fields).forEach(f => {
            f.errors = [];
        });
    }

    resetValues() {
        Object.values(this.fields).forEach(f => {
            f.value = f.defaultValue;
        })
    }
}