import Vue from 'vue'
import Vuex from 'vuex'
import Api from '@/utils/api.js'

const api = new Api();

Vue.use(Vuex);

const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const setProduct = function (state, product, basketProducts = undefined) {
    let p = product;
    if (basketProducts === undefined) {
        basketProducts = state.basket.items;
    }
    p.became_available_at = new Date(p.became_available_at);
    p.slug = p.model + '-' + p.color;
    let assts = {};
    p.assortments_sort = p.assortments
    p.assortments.forEach(aId => {
        let a = JSON.parse(JSON.stringify(state.assortments.find(a => a.id === aId)));
        let basketProduct = basketProducts.find(bp => bp.product_id === p.id);
        if (basketProduct) {
            basketProduct = basketProduct.product;
            let assort = Object.values(basketProduct.assortments).find(assort => assort.id === a.id);
            a.quantity = assort.quantity || 0;
        } else {
            a.quantity = 0;
        }
        assts[a.id] = a
    });

    p.assortments = assts;
    return Vue.set(state.products, p.id, p);
};

export default new Vuex.Store({
    state: {
        initialized: false,
        userRequested: false,
        user: {permissions: [], groups: []}, // hack to avoid errors
        assortments: [],
        products: {},
        basket: {items: []}, // form of {product_id: {assortment_id: quantity}}
        basketInitialized: false,
        draggedSetup: null
    },
    getters: {
        getDraggedSetup: state => {
            return state.draggedSetup
        },
        getUser: state => {
            return state.user;
        },
        getAsssortments: state => {
            return state.assortments;
        },
        getTranslations: state => {
            return state.translations;
        },
        inBasket: state => productId => {
            return productId in state.basket;
        },
        getBasketQuantity: state => (productId, assortmentId) => {
            return state.products[productId].assortments[assortmentId].quantity;
        },
        getBasketItems: state => {
            let items = [];
            state.basket.items.forEach(i => items.push(i.product));
            let slugify = function (p) {
                return p.model + '-' + p.color;
            };
            let sortByFunction = function (a, b) { // by name
                if (slugify(a) < slugify(b)) return -1;
                if (slugify(a) > slugify(b)) return 1;
                return 0;
            };
            items.sort(sortByFunction);
            // console.log(items);
            return items;
        },
        getProduct: state => productId => {
            return state.products[productId]
        }
    },
    mutations: {
        initialize(state) {
            state.initialized = true;
        },
        setUser(state, user) {
            state.user = user;
        },
        setDraggedSetup: (state, setup) => {
            state.draggedSetup = setup
        },
        removeAssortmentFromProduct(state, {product, assortment_id}) {
            if (product.id in state.products) {
                let p = state.products[product.id];
                if (assortment_id in p.assortments) {
                    Vue.delete(p.assortments, assortment_id)
                }
            }
        },
        setProduct(state, product) {
            // // eslint-disable-next-line no-console
            // console.log(state, product)
            if (product.assortments.length === 0) {
                return Vue.delete(state.products, product.id);
            } else {
                setProduct(state, product);
            }
        },
        setProducts(state, products) {
            let basketProducts = state.basket.items;
            products.forEach(p => {
                setProduct(state, p, basketProducts);
            });
        },
        setAssortments(state, assortments) {
            assortments.forEach(a => state.assortments.push(a));
        },
        setBasket(state, basket) {
            basket.items.forEach(i => {
                let assts = {};
                let found = false;
                i.product.assortments.forEach(aId => {
                    let a = JSON.parse(JSON.stringify(state.assortments.find(a => a.id === aId)));
                    if (i.assortment_id === aId) {
                        a.quantity = i.quantity;
                        found = true;
                    } else {
                        a.quantity = 0;
                    }
                    assts[a.id] = a
                });
                if (!found) {
                    // eslint-disable-next-line no-console
                    console.log('product no longer available', i);
                    // this.$app.$toast.error(i);
                }
                i.product.assortments = assts;
            });

            let basketProducts = [];
            let groups = groupBy(basket.items, 'product_id');
            // eslint-disable-next-line no-unused-vars
            for (let [product_id, products] of Object.entries(groups)) {
                let product = products[0].product;
                products.splice(1).forEach(p => {
                    Object.values(p.product.assortments).forEach(a => {
                        product.assortments[a.id].quantity += a.quantity
                    })
                });
                basketProducts.push(products[0]);
            }

            basket.items = basketProducts;
            state.basket = basket;
            state.basketInitialized = true;
            // console.log('basket set', basket);
        },
        setBasketProduct(state, {productId, assortmentId, quantity}) {
            if (productId in state.products) {
                let assortments = state.products[productId].assortments;
                if (assortmentId in assortments) {
                    assortments[assortmentId].quantity = quantity;
                } else {
                    let assort = JSON.parse(JSON.stringify(state.assortments.find(a => a.id === assortmentId)));
                    assort.quantity = quantity;
                    Vue.set(assortments, assortmentId, assort);
                }
            }
            let basketProduct = state.basket.items.find(p => p.product_id === productId);
            if (basketProduct) {
                basketProduct = basketProduct.product;
                if (assortmentId in basketProduct.assortments) {
                    basketProduct.assortments[assortmentId].quantity = quantity;
                }
            }
        },
        // setProductionDateOnProduct(state, {productId, productionDate}) {
        //     state.products[productId].sekaci_plans[0].production_date = productionDate;
        // },
        // LOAD_SEKACIPLANS(state, plans) {
        //     plans.forEach(p => p.product_id = p.product.id);
        //     plans.forEach(p => p.production_date = new Date(p.production_date));
        //     let groups = groupBy(plans, 'product_id'); // slug = product name
        //     let basketProducts = state.basket.items;p.assortments
        //     for (let [product_id, sekaci_plans] of Object.entries(groups)) {
        //         if (!(product_id in state.products)) {
        //             let p = setProduct(state, sekaci_plans[0].product, basketProducts);
        //             Vue.set(p, 'sekaci_plans', sekaci_plans);
        //         }
        //     }
        // },
        LOAD_WHOLESALE_NOTIFICATIONS(state, notifications) {
            notifications.forEach(n => {
                if (n.product in state.products) {
                    Vue.set(state.products[n.product], 'notification_created_at', new Date(n.created_at));
                }
            })
        },
        USER_REQUESTED(state) {
            state.userRequested = true;
        }
    },
    actions: {
        initialize(context) {
            return context.dispatch('loadCurrentUser').then(() => {
                context.commit('initialize');
            });
        },
        loadCurrentUser(context) {
            if (context.state.userRequested) {
                return
            }
            context.commit('USER_REQUESTED');
            return api
                .get('/api/user/')
                .then(response => {
                    context.commit('setUser', response.data)
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error)
                });
        },

        refreshCurrentUser(context) {
            context.commit('USER_REQUESTED');
            return api
                .get('/api/user/')
                .then(response => {
                    context.commit('setUser', response.data)
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error)
                });
        },
        setBasketQuantity(context, {productId, assortmentId, quantity}) {
            return api.post('/api/wholesale/basket/change/', {
                product_id: productId,
                assortment_id: assortmentId,
                quantity: quantity,
            }).then(response => {
                let r = response.data;
                if (r.display_message)
                    this.$app.$toast.success(r.message);

                context.commit('setBasketProduct', {
                    productId: productId,
                    assortmentId: assortmentId,
                    quantity: quantity
                });
                context.commit('setBasket', r.basket)
            }).catch(error => {
                // eslint-disable-next-line no-console
                console.log(error)
            });
        },
        flushBasket(context) {
            return api.post('/api/wholesale/basket/flush/')
                .then(response => {
                    context.commit('setBasket', response.data)
                }).catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error)
                });
        },
        loadAssortments(context) {
            // console.log('loadAssortments');
            return api
                .get('/api/assortments')
                .then(response => {
                    context.commit('setAssortments', response.data)
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error)
                });
        },
        async loadProducts(context) {
            // console.log('loadProducts');
            return api.get('/api/wholesale/product/').then(response => {
                context.commit('setProducts', response.data);
                // context.dispatch('loadSekaciPlans');
            }).catch(error => {
                // eslint-disable-next-line no-console
                console.log(error)
            }).finally(() => {
                this.loading = false;
            });
        },
        async loadBasket(context) {
            // console.log('loadBasket');
            return api.get('/api/wholesale/basket/')
                .then(response => {
                    context.commit('setBasket', response.data)
                });
        },

        // async loadSekaciPlans({commit, dispatch}) {
        //     // eslint-disable-next-line no-console
        //     console.log('loadSekaciPlans');
        //     return api.get('/api/manufacturing/sekaciplan/', {
        //         status: 'NEW',
        //         ordering: 'production_date'
        //     }).then(r => {
        //         commit('LOAD_SEKACIPLANS', r.data);
        //         dispatch('loadWholesaleNotifications');
        //     })
        // },

        async loadWholesaleNotifications({commit}) {
            // eslint-disable-next-line no-console
            console.log('loadWholesaleNotifications');
            return api.get('/api/wholesale/notifications/', {
                email_not_sent: true,
            }).then(r => {
                commit('LOAD_WHOLESALE_NOTIFICATIONS', r.data);
            })
        }
    }
})
