<template>
    <div class="sekaci-plan-execute-task">
        <div class="card" v-if="dialog">
            <v-stepper v-model="step" height="50%">
                <v-stepper-header>
                    <v-stepper-step :complete="step > 1" step="1">
                        SEKACÍ PLÁN
                        <small>{{sekaciPlanProcess.sekaci_plan.sekaci_plan}}</small>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="step > 2" step="2">
                        {{$translate('task').toUpperCase()}}
                        <small>{{$translate(task).toUpperCase()}}</small>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="step > 3" step="3">
                        {{$translate('employee').toUpperCase()}}
                        <small>{{employee}}</small>
                    </v-stepper-step>
                </v-stepper-header>
            </v-stepper>
        </div>
        <!--            </v-card>-->
        <!--        </v-dialog>-->
    </div>
</template>

<script>
    import sequenceListener from "../../utils/SequenceListener";
    import Api from '@/utils/api.js'

    const api = new Api();

    export default {
        name: "SekaciPlanExecuteTask",
        elClass: 'sekaci-plan-execute-task',
        data() {
            return {
                dialog: false,
                sekaciPlanProcessId: '',
                sekaciPlanProcess: {},
                task: '',
                step: 2,
                employee: '',
            }
        },
        watch: {},
        methods: {
            reset() {
                this.step = 1;
                this.task = '';
                this.employee = '';
            },
            taskTriggered(task) {
                this.reset();
                this.dialog = true;
                if (this.sekaciPlanProcess.open_tasks.includes(task)) {
                    this.task = task;
                    this.step = 3
                }

            },
            employeeTriggered(employeeId) {
                if (this.task === '') return;
                api.get(`/api/user/${employeeId}`).then(
                    r => this.employee = r.data.full_name
                )
            },
        },
        mounted() {
            let dataClass = this.$el.className + '-data';
            let dataEl = this.$el.parentElement.getElementsByClassName(dataClass)[0];
            this.sekaciPlanProcessId = dataEl.dataset.sekaciPlanProcessId;
            this.taskListener = sequenceListener(this.taskTriggered, 25, "==t");
            this.employeeListener = sequenceListener(this.employeeTriggered, 25, "==e");
            api.get(`/api/manufacturing/sekaciplanprocess/${this.sekaciPlanProcessId}`).then(
                r => this.sekaciPlanProcess = r.data
            )
        },
        destroyed() {
            this.taskListener();
            this.employeeListener();
        },
        computed: {}
    }
</script>

<style scoped lang="less">
    /deep/ .v-dialog {
        width: 50vw !important;
    }
</style>