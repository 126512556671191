<template>
  <v-data-table
      :key="`${product.id}-assrtTable`"
      :items="asts"
      hide-default-footer
      transition="fade-transition"
      :mobile-breakpoint="Infinity"
      :items-per-page="20"
  >
    <template v-slot:header="props">
      <thead>
      <tr>
        <th style="width: 24px;">A</th>
        <th v-for="s in astsSizes"
            :key="`${product.id}-${s}`" class="text-center"
        >{{ s }}
        </th>
        <th class="text-center" style="width: 24px;" v-if="displayButtons"></th>
        <th class="text-center" style="width: 30px;">
          <v-icon small>shopping_cart</v-icon>
        </th>
        <th class="text-center" style="width: 24px;" v-if="displayButtons"></th>
        <th class="text-center" style="width: 24px;" v-if="displayButtons"></th>
        <th class="text-center hide-on-small-only" style="width: 24px;" v-if="displayButtons"></th>
        <th class="text-center" style="width: 24px;"
            v-if="!basket && $hasPermission('wholesale.change_wholesaleproduct')">
          <v-btn x-small icon text @click="removeWholesaleProductAll()">
            <v-icon>clear</v-icon>
          </v-btn>
        </th>
      </tr>
      </thead>
    </template>
    <template v-slot:item="props">
      <tr class="assortment-row" :key="`${product.id}-${props.item.id}`">
        <td>{{ props.item.name }}</td>
        <td v-for="s in astsSizes" class="text-center"
            :key="`${product.id}-${props.item.id}-${s}`">{{ getSizeFromAssort(props.item, s) }}
        </td>
        <td v-if="displayButtons">
          <v-btn x-small outlined class="mr-1" @click="decrementBasket(props.item)">
            -{{ props.item.total_pairs }}
          </v-btn>
        </td>
        <td :class="[displayButtons ? '' : 'basketValue']">
          <v-text-field
              single-line
              style="width: 100%; height: 24px; max-width: 40px;"
              label=""
              :class="[props.item.quantity ? 'green lighten-4' : '', 'pa-0 ma-0']"
              :value="props.item.quantity"
              @change="updateBasketQuantity(props.item, $event)"
              @focus="$event.target.select()"
          ></v-text-field>

        </td>
        <td v-if="displayButtons">
          <v-btn x-small outlined class="ml-1" @click="incrementBasket(props.item)">
            +{{ props.item.total_pairs }}
          </v-btn>
        </td>
        <td v-if="displayButtons">
          <v-btn x-small outlined class="mr-1 ml-1" @click="incrementBasket(props.item, 36)">
            +36
          </v-btn>
        </td>
        <td v-if="displayButtons" class="hide-on-small-only">
          <v-btn x-small icon @click="removeBasket(props.item)">
            <v-icon>delete_forever</v-icon>
          </v-btn>
        </td>

        <td v-if="!basket && $hasPermission('wholesale.change_wholesaleproduct')" class="basketValue text-center">
          <v-btn x-small icon text @click="removeWholesaleProduct(props.item)">
            <v-icon>clear</v-icon>
          </v-btn>
        </td>
      </tr>

    </template>
    <template v-slot:foot>
      <tfoot>
      <tr v-if="showExpansionPanel">
        <td :colspan="100" class="text-center pa-0">
          <v-btn @click="firstOnly = !firstOnly" class="ma-0 pa-0" style="height: 24px;" small block text>
            <v-icon>{{ expansionIcon }}</v-icon>
          </v-btn>
        </td>
      </tr>
      </tfoot>
    </template>
  </v-data-table>
</template>
<script>
import Api from '@/utils/api.js'

const api = new Api();
export default {
  name: 'WholesaleCatalogueProductCardAssortment',
  data() {
    return {
      firstOnly: true,
      dummy: 0,
    }
  },
  props: {
    product: {},
    displayButtons: {
      type: Boolean,
      default: false
    },
    displayAllRows: {
      type: Boolean,
      default: false
    },
    displayOnlyPopulatedAssortments: {
      type: Boolean,
      default: false
    },
    basket: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    expansionIcon() {
      if (this.firstOnly) return 'keyboard_arrow_down';
      return 'keyboard_arrow_up';
    },
    assortments() {
      let assorts = Object.values(this.product.assortments);
      if (this.displayOnlyPopulatedAssortments) {
        assorts = assorts.filter(a => a.quantity > 0)
      }

      if (this.product.assortments_sort == undefined)
        return assorts

      let indexed_assortment_positions = this.product.assortments_sort.reduce((acc, cur, i) => {
        acc[cur] = i;
        return acc;
      }, {});

      assorts.sort((a, b) => {
        return indexed_assortment_positions[a.id] > indexed_assortment_positions[b.id] ? 1 : -1
      })

      return assorts
    },
    asts() {
      if (this.displayAllRows) return this.assortments;
      if (this.firstOnly) {
        let basketAssortments = this.assortments.filter(a => a.quantity > 0);
        if (basketAssortments.length > 0) return basketAssortments;
        return this.assortments.slice(0, 1);
      }
      return this.assortments;
    },
    astsSizes() {
      let asts = this.asts;
      let sizes = new Set();
      asts.forEach(a => {
        Object.entries(a).forEach(([key, value]) => {
          if (value > 0 && key.startsWith('size')) {
            // eslint-disable-next-line
            let [_, size] = key.split('_');
            sizes.add(size)
          }
        })
      });
      return Array.from(sizes).sort();
    },
    showExpansionPanel() {
      if (this.displayAllRows) return false;
      return this.assortments.length > 1; // && this.assortments.length !== this.asts.length;
    },
  },
  methods: {
    getSizeFromAssort(assort, size) {
      const val = assort['size_' + size];
      if (val > 0) return val;
      return '';
    },
    incrementBasket(assortment, quantity = 0) {
      if (quantity === 0) quantity = assortment.total_pairs;
      this.updateBasketQuantity(assortment, quantity, true);
    },
    removeBasket(assortment) {
      this.updateBasketQuantity(assortment, 0);
    },
    async removeWholesaleProduct(assortment) {
      let res = await this.$confirm('Odstrániť sortiment?');
      if (res) {
        let payload = {
          product: this.product.id,
          assortments: [assortment.id],
        };
        await api.post('/api/wholesale/product/disable/', payload).then(r => {
          this.$store.commit('setProduct', r.data);
          this.$emit('update:product', r.data);
        });

      }
    },
    async removeWholesaleProductAll() {
      let res = await this.$confirm('Odstrániť všetky sortimenty?');
      if (res) {
        let payload = {
          product: this.product.id,
          assortments: Object.values(this.product.assortments).map(a => a.id), // need ints
        };
        await api.post('/api/wholesale/product/disable/', payload).then(r => {
          this.$store.commit('setProduct', r.data);
          this.$emit('update:product', r.data);
        });

      }
    },
    decrementBasket(assortment) {
      this.updateBasketQuantity(assortment, -assortment.total_pairs, true);
    },
    getBasketQuantity(assortment) {
      return assortment.quantity;
      // return this.$store.getters.getBasketQuantity(this.product.id, assortment.id);
    },
    // eslint-disable-next-line
    _updateBasketQuantity: _.debounce(function (assortment, quantity) {
      this.$store.dispatch({
        type: 'setBasketQuantity',
        productId: this.product.id,
        assortmentId: assortment.id,
        quantity: quantity,
      });
    }, 1),
    updateBasketQuantity(assortment, quantity, increment = false) {
      quantity = parseInt(quantity);
      // const currentQty = this.getBasketQuantity(assortment);
      const currentQty = assortment.quantity;
      const newQty = currentQty + quantity;
      let payload;
      if (newQty < 1) {
        payload = 0;
      } else if (newQty % assortment.total_pairs !== 0) {
        payload = currentQty;
      } else {
        payload = increment ? newQty : quantity;
      }

      this._updateBasketQuantity(assortment, payload);

      this.$store.commit({
        type: 'setBasketProduct',
        productId: this.product.id,
        assortmentId: assortment.id,
        quantity: -10000000 // forces overriding input value in case it is incorrect.
      });

      const productId = this.product.id;
      this.$nextTick(() => this.$store.commit({
        type: 'setBasketProduct',
        productId: productId,
        assortmentId: assortment.id,
        quantity: payload
      }));
    }
  }
}
</script>

<style scoped lang="less">
th, td {
  padding: 0;
  font-weight: 300;
  font-size: 12px;
}

td {
  height: 24px !important;
}

thead th:first-child, tbody td:first-child {
  padding: 0 !important;
}

thead th:not(:nth-child(1)), tbody td:not(:nth-child(1)) {
  padding: 0 !important;
}

tr, tr > th {
  height: 24px !important;
}


/deep/ input, /deep/ .v-input, /deep/ .v-input__slot {
  height: 24px !important;
  font-size: 12px !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center !important;
}

/deep/ .v-input__slot input {
  border-bottom: none !important;
}

.basketValue {
  border-left-color: rgba(0, 0, 0, 0.12);
  border-left-style: solid;
  border-left-width: 1px;
  height: 24px !important;
  margin: 0px !important;
}

/deep/ tbody > .assortment-row:hover {
  background: none !important;
}

tbody > .assortment-row:last-child {
  border-bottom: none;
}

/deep/ tfoot tr {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-top-style: solid !important;
}

.text-center {
  text-align: center
}

.mr-1 {
  margin-right: 4px;
}

.ml-1 {
  margin-left: 4px;
}

/deep/ .v-data-table__wrapper {
  overflow-x: hidden;
}
</style>