<template>
    <el-autocomplete
            v-model="slug"
            style="width: 100%"
            :select-when-unmatched="true"
            :fetch-suggestions="querySearchAsync"
            placeholder="Search..."
            @select="handleSelect"
            size="mini"
            ref="searchBarAutocomplete"
    >
        <template slot-scope="{ item }">
            <div class="autocomplete-item">
                <!--                <el-image style="width: 40px; height: 30px" :src="item.img_url"></el-image>-->
                <p>
                    <span>
                        {{item.slug}}
                    </span>
                </p>
            </div>
        </template>
    </el-autocomplete>
</template>

<script>
    import Api from '@/utils/api.js'

    const api = new Api()

    export default {
        name: "ElSearchBar",
        // components: {
        //     AutocompleteItem
        // },
        data() {
            return {
                links: [],
                timeout: null,
                loading: false,
                slug: '',
                url: ''
            };
        },
        props: {
            value: {}
        },
        methods: {
            querySearchAsync(queryString, cb) {
                if (!queryString || queryString.length < 2) return cb([]);
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.loading = true
                    api.get('/api/search/', {slug: queryString})
                        .then(r => {
                            cb(r.data);
                        }).finally(() => this.loading = false)
                }, 200);
            },
            handleSelect(item) {
              // eslint-disable-next-line no-undef
                if (item.url) Turbolinks.visit(item.url)
            },
            focus() {
                this.$refs.searchBarAutocomplete.focus()
            }
        },
    }
</script>


<style scoped>

    .autocomplete-item {
        display: flex;
        align-items: center;
        padding: 4px 0;
    }

    ::v-deep .el-input input {
        margin-bottom: 12px;
    }

    .autocomplete-item p {
        margin: 0;
        margin-left: 10px;
        padding: 0;
    }
</style>