<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div id="order-item-change-manufacturing-variant-app">
    <span>{{ manufacturing_variant.slug }}</span>
    <v-dialog v-model="dialog" persistent max-width="500">
      <template v-slot:activator="{ on }">
        <v-btn
            class="ml-2"
            color="indigo"
            x-small
            icon
            outlined
            v-on="on">
          <v-icon small>edit</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <v-form ref="form">
            <v-container grid-list-md>
              <v-layout align-center justify-center>
                <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="400px"
                    min-width="400px"
                >
                  <template v-slot:activator="{ on }">
                    <v-radio-group v-model="selected_manufacturing_variant_id"
                                   :error-messages="form.fields.to_manufacturing_variant.errors"
                    >
                      <v-radio
                          v-for="v in available_manufacturing_variants"
                          :key="v.id"
                          :label="v.slug"
                          :value="v.id"
                      ></v-radio>
                    </v-radio-group>
                  </template>
                </v-menu>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog=false">{{ $translate('Close') }}</v-btn>
          <v-btn color="primary" text
                 :loading="updating"
                 :disabled="updating" @click="save">{{ $translate('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {Form, IntegerField} from "@/form";

export default {
  name: 'OrderItemProcessChangeManufacturingVariant',
  elClass: 'order-item-change-manufacturing-variant-app',
  requiredPermission: 'order.can_start_orderitemchangemanufacturingvariant',
  data() {
    return {
      dialog: false,
      updating: false,
      product_id: '',
      selected_manufacturing_variant_id: '',
      manufacturing_variant: {
        id: '',
        slug: '',
      },
      process_id: '',
      available_manufacturing_variants: [],
      menu1: false,
    }
  },
  async mounted() {
    const elem = this.$el.parentElement.lastElementChild;
    this.process_id = elem.dataset.process;
    this.product_id = elem.dataset.productId;
    this.manufacturing_variant.id = elem.dataset.manufacturingVariantId;
    this.manufacturing_variant.slug = elem.dataset.manufacturingVariantSlug;
    this.available_manufacturing_variants = await this.$api.getManufacturingVariants({product: this.product_id})
    this.selected_manufacturing_variant_id = parseInt(this.manufacturing_variant.id)
  },
  methods: {
    async save() {
      this.updating = true;
      const resolve = () => {
        this.dialog = false;
        this.manufacturing_variant = this.available_manufacturing_variants.find(v => v.id === this.selected_manufacturing_variant_id);

        var reloadWithTurbolinks = (function () {
          var scrollPosition

          function reload() {
            scrollPosition = [window.scrollX, window.scrollY]
            // eslint-disable-next-line no-undef
            Turbolinks.visit(window.location.toString().slice(0, window.location.toString().length - 1), {action: 'replace'})
          }

          document.addEventListener('turbolinks:load', function () {
            if (scrollPosition) {
              window.scrollTo.apply(window, scrollPosition)
              scrollPosition = null
            }
          })

          return reload
        })()
        reloadWithTurbolinks()
      };
      await this.form.call(resolve);
      this.updating = false;
    },
  },
  computed: {
    form() {
      const apiUrl = '/api/workflow/tasks/restorderitemchangemanufacturingvariant/start/';
      const fields = {
        to_manufacturing_variant: new IntegerField(this.selected_manufacturing_variant_id),
        order_item_process: new IntegerField(this.process_id)
      };
      return new Form(apiUrl, fields);
    },
  },

}
</script>

<style scoped lang="less">
/deep/ .v-date-picker-table--date td {
  padding: 0 !important;
}

/deep/ .v-date-picker-table--date tr {
  border-style: none !important;
}

/deep/ .v-btn.v-btn--outline {
  margin-top: 0;
  margin-bottom: 0;
}

.v-btn--icon.v-btn--small {
  width: 20px;
  height: 20px;
}

/deep/ .v-btn--icon .v-icon {
  font-size: 12px !important;
}

/deep/ button:focus {
  background-color: unset;
}
</style>