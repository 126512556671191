/* eslint-disable */
var _url = ''; // formset hack

export function initForms($container, url) {
    if (url !== undefined) _url = url;
    $container = $($container)
    // Formsets
    // https://bitbucket.org/ionata/django-formset-js
    $('.formset-field').formset({
        animateForms: true,
        newFormCallback: initForms
    })

    // Select
    // http://materializecss.com/forms.html#select
    $container
        .find('select')
        .not('.disabled')
        .not('.material-ignore')
        .formSelect()

    var lang = jQuery(":root").attr('lang')
    if (lang) {
        jQuery.datetimepicker.setLocale(lang.substr(0, 2));
    }

    // Date/DateTime/Time
    // https://github.com/xdan/datetimepicker
    $container
        .find('[data-form-control="date"]')
        .each(function () {
            $(this).datetimepicker({
                format: this.dataset.dateFormat,
                timepicker: false,
                mask: false,
                scrollInput: false
            })
        })
    $container
        .find('[data-form-control="time"]')
        .each(function () {
            $(this).datetimepicker({
                format: this.dataset.dateFormat,
                datepicker: false,
                timepicker: true,
                mask: false,
                scrollInput: false
            })
        })
    $container.find('[data-form-control="datetime"]').each(
        function () {
            $(this).datetimepicker({
                format: this.dataset.dateFormat,
                datepicker: true,
                timepicker: true,
                mask: false,
                scrollInput: false
            })
        })

    // Autocomplete
    // https://github.com/devbridge/jQuery-Autocomplete
    $container.find('[data-form-control="autocomplete"]').each(
        function () {
            var field = $(this).find('input[type="hidden"]').attr('name')

            $(this).find('input[type="text"]')
                .on('focusout.autocomplete',
                    function () {
                        if (!$('.autocomplete-suggestions:visible').length) {
                            var selection = $(this).parent().find('input[type="hidden"]').val()
                            if (!selection) {
                                $(this).val('')
                            }
                        }
                    })
                .devbridgeAutocomplete({
                    dataType: 'json',
                    onSelect: function (suggestion) {
                        $(this).parent().find('input[type="hidden"]').val(suggestion.data.id)
                        $(this).parent().find('input[type="hidden"]').trigger('change');
                    },
                    onInvalidateSelection: function () {
                        $(this).parent().find('input[type="hidden"]').val('')
                        $(this).parent().find('input[type="hidden"]').trigger('change');
                    },
                    params: {
                        field: $(this)
                            .find('input[type="hidden"]')
                            .attr('name')
                    },
                    serviceUrl: _url,
                    showNoSuggestionNotice: true,
                    type: 'OPTIONS',
                    ajaxSettings: {
                        beforeSend: function (xhr, options) {
                            xhr.setRequestHeader('X-Request-Autocomplete', options.data)
                        }
                    }
                })
            $(this).find('.input-field-action').on('click', function (event) {
                event.preventDefault();
                event.stopPropagation();
                var that = $(this);
                that.parent().find('input[type="hidden"]').val('');
                that.parent().find('input[type="text"]').val('');
                that.parent().find('input[type="hidden"]').trigger('change');
            })
        })
    $container.find('[data-form-control="autocomplete-multi"]').each(
        function () {

            var $chips = $(this).find('.chips'),
                initial_data = $(this).find('select option').map(function () {
                        return {
                            tag: $(this).text(),
                            id: $(this).val()
                        }
                    }
                );

            var mChip = M.Chips.init($chips[0], {
                data: Array.from(initial_data),
                onChipDelete: function (e, chip) {
                    $(this.el).parent().parent().find('select option')
                        .filter(function () {
                            return $(this).text() == chip.childNodes[0].textContent
                        })
                        .remove();
                    $(this).parent().parent().find('select').trigger('change');
                }
            });
            var origIsValid = mChip._isValid;
            mChip._isValid = function (chip) {
                if (!chip.id) {
                    return false;
                }
                return origIsValid.call(this, chip);
            }

            $chips.find('input[class="input"]').devbridgeAutocomplete({
                dataType: 'json',
                lookupFilter: function (suggestion) {
                    return !$.inArray(suggestion.data.id, $(this).parent().find('select').val())
                },
                params: {
                    field: $(this).find('select').attr('name')
                },
                serviceUrl: _url,
                showNoSuggestionNotice: true,
                type: 'OPTIONS',
                ajaxSettings: {
                    beforeSend: function (xhr, options) {
                        xhr.setRequestHeader('X-Request-Autocomplete', options.data)
                    }
                },
                onSelect: function (suggestion) {
                    mChip.addChip({'tag': suggestion.value, 'id': suggestion.data.id});
                    $(this).parent().find('input').val('');
                    $(this).parent().parent().find('select').append(
                        $("<option></option>")
                            .attr('selected', 'selected')
                            .attr("value", suggestion.data.id)
                            .text(suggestion.value));
                    $(this).parent().parent().find('select').trigger('change');
                }
            });
        })

}

export function destroyForms($container) {
    $container = $($container);
    // Select
    $container
        .find('select')
        .not('.disabled')
        .not('.material-ignore')
        .formSelect('destroy')

    // Date/DateTime/Time
    $container
        .find('[data-form-control="date"],[data-form-control="time"],[data-form-control="datetime"]')
        .datetimepicker('destroy');

    // Autocomplete
    $container
        .find('[data-form-control="autocomplete"] input[type="text"]')
        .devbridgeAutocomplete('destroy')
    $container
        .find('[data-form-control="autocomplete-multi"] .chips')
        .off()
    $container
        .find('[data-form-control="autocomplete-multi"] input[type="text"]')
        .devbridgeAutocomplete('destroy')
}

/* eslint-enable */