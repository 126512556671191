export default function sequenceListener(callback, timeout = 2000, startSeq = "123", endSeq = "---") {
    const sequenceStart = startSeq;
    const sequenceEnd = endSeq;
    const keyBuffer = [];
    const sequenceBuffer = [];
    let timer = undefined;
    let listening = false;

    const handler = (event) => {
        const shift = event.getModifierState('Shift');
        const key = shift ? event.key.toUpperCase() : event.key;
        if (key === "SHIFT") return;
        if (listening) {
            event.preventDefault();
            sequenceBuffer.push(key);
            const endOfSequence = sequenceBuffer.join('').length - sequenceEnd.length;
            if (endOfSequence >= 0 && sequenceBuffer.join('').indexOf(sequenceEnd) === endOfSequence) {
                const data = sequenceBuffer.slice(0, endOfSequence).join('');
                if (callback !== undefined) callback(data);
                listening = false;
                sequenceBuffer.splice(0, sequenceBuffer.length)
            }
            // reset timer after every key
            clearTimeout(timer);
            timer = setTimeout(() => {
                const data = sequenceBuffer.join('');
                if (callback !== undefined) callback(data);
                listening = false;
                sequenceBuffer.splice(0, sequenceBuffer.length)
            }, timeout)
        } else {
            keyBuffer.push(key)
        }

        let input = keyBuffer.join('');

        if (sequenceStart.indexOf(input) === 0) {
            if (input === sequenceStart) {
                listening = true;
                keyBuffer.splice(0, keyBuffer.length);
                // start timer after startSequence detected
                timer = setTimeout(() => {
                    const data = sequenceBuffer.join('');
                    if (callback !== undefined) callback(data);
                    listening = false;
                    sequenceBuffer.splice(0, sequenceBuffer.length)
                }, timeout)
            }
        } else {
            keyBuffer.splice(0, keyBuffer.length);
        }
    };

    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
}

