<template>
  <v-app sticky-container>
    <router-view/>
  </v-app>
</template>

<script>
import Sticky from 'vue-sticky-directive'

export default {
  name: 'App',
  directives: {
    Sticky
  },
  data() {
    return {
      //
    }
  },
}
</script>

<style scoped lang="less">
/deep/ .v-application--wrap {
  min-height: auto;
}

.theme--light {
  background-color: inherit;
}

/deep/ th {
  font-weight: 700;
}

/deep/ button:focus {
  background-color: unset;
}

</style>