import axios from 'axios'

export default class Api {
    constructor() {
        this.http = axios.create()
        this.http.defaults.xsrfCookieName = 'csrftoken'
        this.http.defaults.xsrfHeaderName = 'X-CSRFToken'
    }

    async post(url, params) {
        return await this.http.post(url, params)
    }

    async get(url, params) {
        return await this.http.get(url, {params: params})
    }

    async patch(url, params) {
        return await this.http.patch(url, params)
    }

    async put(url, params) {
        return await this.http.put(url, params)
    }
}