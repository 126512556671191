<template>
    <div class="create-manufacturing-product-variant">
        <v-dialog v-model="dialog" persistent max-width="90%" aspect-ratio="1" max-height="90%" scrollable
                  fullscreen hide-overlay transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ on }">
                <v-btn color="primary" small text outline v-on="on" @click="openDialog" ma-0>
                    {{$translate('Add')}}
                </v-btn>
            </template>
            <v-dialog v-model="editDialog" max-width="90%">
                <DjangoForm
                        ref="djangoEditForm"
                        :formUrl="editVariantUrl"
                        :openUrlLink="false"
                        :formOnly="true"
                        :hasActions="true"
                        :refreshManually="true"
                        :flatCard="true"
                        :titleText="$translate('Change')"
                        :submitDoneBtnText="$translate('Save')"
                        @done="update"
                        @update="update"
                        @close="update"
                />
            </v-dialog>
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{$translate('Add Manufacturing Variant')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="success">{{$translate('OK')}}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-container fluid grid-list-xl>
                        <v-layout row wrap>
                            <v-flex xs12 md3>
                                <ManufacturingProductVariantAutolookup
                                        v-model="searchVariant"
                                        :url="searchUrl"
                                        searchKwarg="slug"
                                        label=""
                                ></ManufacturingProductVariantAutolookup>
                                <v-card>
                                    <v-data-table
                                            :headers="headers"
                                            :items="variants"
                                            class="elevation-1"
                                            hide-default-footer
                                            dense
                                    >
                                        <template v-slot:items="props">
                                            <tr
                                                    @click="fetchVariant(props.item.id)"
                                                    :active="isDefaultVariantId(props.item.id)"
                                            >
                                                <td class="">{{ props.item.name }}</td>
                                                <td class="justify-center">
                                                    <v-icon
                                                            small
                                                            class="mr-2"
                                                            @click.stop="editItem(props.item.id)"
                                                    >
                                                        edit
                                                    </v-icon>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-flex>
                            <v-flex xs12 md9>
                                <DjangoForm
                                        ref="djangoForm"
                                        class="materialsContainer"
                                        :formUrl="variantUrl"
                                        :openUrlLink="false"
                                        :formOnly="true"
                                        :hasActions="true"
                                        :refreshManually="true"
                                        :flatCard="true"
                                        :submitCancelBtn="false"
                                        :titleText="$translate('Create')"
                                        :submitDoneBtnText="$translate('Add')"
                                        @done="success"
                                        @update="update"
                                        @close="success"
                                />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Api from '@/utils/api.js'
    import DjangoForm from "../DjangoForm";
    import ManufacturingProductVariantAutolookup from '../ManufacturingProductVariantAutolookup';

    const api = new Api();
    export default {
        name: "CreateManufacturingProductVariant",
        elClass: 'create-manufacturing-product-variant',
        components: {DjangoForm, ManufacturingProductVariantAutolookup},
        data() {
            return {
                dialog: false,
                editDialog: false,
                searchVariant: {},
                searchUrl: "/api/manufacturing/productvariant/",
                variants: [],
                currentVariantId: '',
                editedProductVariantId: '',
                url: '',
                productId: '',
                title: '',
                headers: [
                    // {text: 'Produkt', value: 'product', sortable: false},
                    {text: 'Meno', value: 'name', sortable: false},
                    {text: '', value: 'name', sortable: false},
                ],
            }
        },
        watch: {
            searchVariant(val) {
                this.fetchVariant(val.id);
            }
        },
        methods: {
            async openDialog() {
                const resp = await api.get(`/api/manufacturing/productvariant/?product=${this.productId}`);
                this.variants = resp.data;
            },
            async update() {
                const resp = await api.get(`/api/manufacturing/productvariant/?product=${this.productId}`);
                this.variants = resp.data;
                this.editDialog = false;
            },
            success() {
                this.dialog = false;
                this.update();
                // eslint-disable-next-line
                Turbolinks.visit(window.location + '#');
            },
            fetchVariant(id, refresh = true) {
                this.currentVariantId = id;
                if (refresh) {
                    this.$nextTick(() => {
                        this.$refs.djangoForm.refresh();
                    });
                }
            },
            isDefaultVariantId(id) {
                return this.currentVariantId === id;
            },
            editItem(id) {
                this.editedProductVariantId = id;
                this.editDialog = true;
                this.$nextTick(() => {
                    this.$refs.djangoEditForm.refresh();
                });
            },
        },
        mounted() {
            let dataClass = this.$el.className + '-data';
            let dataEl = this.$el.parentElement.getElementsByClassName(dataClass)[0];
            this.url = dataEl.dataset.url;
            this.productId = dataEl.dataset.productId;
        },
        computed: {
            variantUrl() {
                return `${this.url}&defaultVariantId=${this.currentVariantId}`
            },
            editVariantUrl() {
                return `/manufacturing/productvariants/${this.editedProductVariantId}/change/`;
            },
        }
    }
</script>

<style scoped lang="less">
    /deep/ .v-datatable__progress {
        border-bottom: none;
    }

    /deep/ .formset-field {
        input {
            /*height: 1.5rem;*/
        }

        .input-field {
            /*margin: 0;*/

            label {
                /*top: -5px;*/
            }
        }

        label {
            /*display: none;*/
        }
    }

    .materialsContainer {
        transform: scale(0.6);
        transform-origin: 0 0;
        width: 166.67%;
    }
</style>