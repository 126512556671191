function handleVueDestruction(vue) {
    document.addEventListener('turbolinks:visit', function teardown() {
        vue.$destroy();
        document.removeEventListener('turbolinks:visit', teardown);
    });
}

const TurbolinksAdapter = {
    beforeMount: function () {
        if (this.$el && this.$el.parentNode) {
            handleVueDestruction(this);
            this.$originalEl = this.$el.outerHTML;
        }
    },
    destroyed: function () {
        if (this.$el && this.$originalEl) {
            if (this.$el.outerHTML) this.$el.outerHTML = this.$originalEl;
        }
    }
};

export default TurbolinksAdapter;