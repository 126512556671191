import DjangoVueForm from './DjangoVueForm'
import SekaciPlanChangeQuantity from './SekaciPlanChangeQuantity'
import SekaciPlanReschedule from './SekaciPlanReschedule'
import ProductionReschedule from './ProductionReschedule'
import CreateManufacturingProductVariant from './CreateManufacturinngProductVariant'
import SekaciPlanExecuteTask from './SekaciPlanExecuteTask'
import SekaciPlanRename from "./SekaciPlanRename";
import OrderItemProcessChangeManufacturingVariant from "./OrderItemProcessChangeManufacturingVariant";

export default [
    DjangoVueForm,
    SekaciPlanChangeQuantity,
    SekaciPlanReschedule,
    ProductionReschedule,
    CreateManufacturingProductVariant,
    SekaciPlanExecuteTask,
    SekaciPlanRename,
    OrderItemProcessChangeManufacturingVariant,
]