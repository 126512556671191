import Vue from 'vue'
import VuetifyConfirm from 'vuetify-confirm'
import App from './App.vue'
import BasketApp from './BasketApp.vue'
import SearchBar from "./SearchBar";
import TurbolinksAdapter from './mixins/turbolinks-adapter'
import router from './router'
import store from './store'
import components from './components/inject'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
import Api from '@/utils/api.js'
// import sequenceListener from "./utils/SequenceListener";
import apiPlugin from '@/utils/apiPlugin.js'
import vuetify from './plugins/vuetify';

const api = new Api()
Vue.use(ElementUI, {locale});

Vue.mixin(TurbolinksAdapter);

Vue.use(VuetifyConfirm, {
    vuetify,
    buttonTrueText: 'Ano',
    buttonFalseText: 'Nie',
});


Vue.prototype.$getUser = function () {
    return this.$store.getters.getUser;
};

var handleErrorMixin = {
    install(Vue) {
        Vue.prototype.$handleError = function (error) {
            this.$message.error(error);
        }
    }
}

Vue.use(handleErrorMixin);
Vue.use(apiPlugin);

const hasPermission = function (permission, user) {
    if (user) return user.permissions.indexOf(permission) !== -1;
    return false;
};

Vue.prototype.$hasPermission = function (permission) {
    let user = this.$getUser();
    return hasPermission(permission, user);
};

Vue.prototype.$hasGroup = function (group) {
    let user = this.$getUser();
    if (user) return user.groups.indexOf(group) !== -1;
    return false;
};

Vue.prototype.$translate = function (string) {
    return window.gettext(string);
};

Vue.prototype.$getApiData = function (model, apiUrl, query = undefined) {
    if (query === undefined)
        query = {};
    return api
        .get(apiUrl, query)
        .then(response => {
            this[model] = response.data;
        })
        .catch(error => {
            // eslint-disable-next-line
            console.log(error)
        })
};

Vue.config.productionTip = false;


document.addEventListener('turbolinks:load', () => {
    if (document.getElementById('vue-basket-app')) {
        new Vue({
            vuetify,
            store,
            render: h => h(BasketApp),
        }).$mount('#vue-basket-app');
    }
    if (document.getElementById('vue-search-bar')) {
        new Vue({
            vuetify,
            store,
            render: h => h(SearchBar),
        }).$mount('#vue-search-bar');
    }

    if (store.state.initialized) {
        injectApps()
    } else {
        // We keep this before the main app so that user is requested here and apps are injected properly.
        store.dispatch('initialize').then(() => {
            injectApps();
        });
    }

    const app = new Vue({
        vuetify,
        router,
        store,
        render: h => h(App),
    }).$mount('#app');
    store.$app = app;
});


// function processScanner(text) {
//     window.Turbolinks.visit(text, {action: 'replace'});
// }
//
// sequenceListener(processScanner, 25, "www.mjartan.sk", "...");


function injectApp(elementClass, vueApp, permission) {
    if (elementClass === undefined) return;
    if (permission !== undefined) {
        if (!hasPermission(permission, store.state.user)) return;
    }
    let dataClass = elementClass + '-data';
    let elements = document.getElementsByClassName(dataClass);
    for (let el of elements) {
        let targetEl = undefined;
        for (let childEl of el.parentElement.childNodes) {
            if (childEl.className === elementClass) {
                targetEl = childEl;
                break;
            }
        }
        if (targetEl === undefined) return;

        targetEl.parentElement

        new Vue({
            vuetify,
            store,
            el: targetEl,
            render: h => h(vueApp),
            mounted() {
                this.$el.classList.add('v-application')
            }
        });
    }
}

function injectApps() {
    components.forEach(component => {
        injectApp(component.elClass, component, component.requiredPermission)
    });
}

window.addEventListener('datatableDrawn', injectApps);
