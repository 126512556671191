import Vue from 'vue'
import Vuetify, {VSnackbar, VBtn, VIcon} from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar'

// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VBtn,
        VIcon
    }
})

const veutifyObj = new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        dark: false,
        // themes: {
        //     light: {
        //         // primary: colors.blue.darken4,
        //         // secondary: colors.red.darken4,
        //         // accent: colors.indigo.darken4,
        //     },
        // },
    },
});

Vue.use(VuetifyToast, {$vuetify: veutifyObj.framework})

export default veutifyObj;