<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div id="sekaci-plan-reschedule-app">
        <span :class="productionDateColor">{{production_date}}</span>
        <v-dialog v-model="dialog" persistent max-width="500">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" small icon text outline v-on="on">
                    <v-icon small>edit</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-text>
                    <v-form ref="form">
                        <v-container grid-list-md>
                            <v-layout align-center justify-center>
                                <v-menu
                                        ref="menu1"
                                        v-model="menu1"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        max-width="290px"
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                :label="$translate('production date').toUpperCase()"
                                                v-model="form.fields.to_date.value"
                                                :error-messages="form.fields.to_date.errors"
                                                persistent-hint
                                                prepend-icon="event"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.fields.to_date.value"
                                                   @input="menu1 = false"
                                                   first-day-of-week="1"
                                    ></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog=false">{{$translate('Close')}}</v-btn>
                    <v-btn color="primary" text
                           :loading="updating"
                           :disabled="updating" @click="save">{{$translate('Save')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {DateField, Form, IntegerField} from "@/form";

    export default {
        name: 'SekaciPlanReschedule',
        elClass: 'sekaci-plan-reschedule-app',
        requiredPermission: 'manufacturing.can_start_sekaciplanprocessreschedule',
        data() {
            return {
                dialog: false,
                updating: false,
                sekaci_plan: '',
                production_date: '',
                sekaci_plan_process_id: '',
                menu1: false,
            }
        },
        mounted() {
            const elem = this.$el.parentElement.lastElementChild;
            this.sekaci_plan = elem.dataset.sekaciPlan;
            this.production_date = elem.dataset.productionDate;
            this.sekaci_plan_process_id = elem.dataset.sekaciPlanProcess;
        },
        methods: {
            async save() {
                this.updating = true;
              // eslint-disable-next-line no-unused-vars
                const resolve = r => {
                    this.dialog = false;
                    this.production_date = this.form.fields.to_date.value;
                };

                await this.form.call(resolve);
                this.updating = false;
            },
        },
        computed: {
            form() {
                const apiUrl = '/api/workflow/tasks/restsekaciplanreschedule/start/';
                const fields = {
                    to_date: new DateField(this.production_date),
                    sekaci_plan_process: new IntegerField(this.sekaci_plan_process_id)
                };
                return new Form(apiUrl, fields);
            },
            productionDateColor() {
                const five_days_past = new Date(new Date() - 1000 * 3600 * 24 * 6);
                let textColor = '';
                if (new Date(this.production_date).getTime() < new Date().getTime())
                    textColor = 'orange-text';
                if (new Date(this.production_date).getTime() < five_days_past.getTime())
                    textColor = 'red-text';
                return textColor;
            }
        },

    }
</script>

<style scoped lang="less">
    /deep/ .v-date-picker-table--date td {
        padding: 0 !important;
    }

    /deep/ .v-date-picker-table--date tr {
        border-style: none !important;
    }

    /deep/ .v-btn.v-btn--outline {
        margin-top: 0;
        margin-bottom: 0;
    }
</style>