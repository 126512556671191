<template>
    <v-autocomplete
            v-bind:value="value"
            v-on:input="$emit('input', $event)"
            :error-messages="errors"
            :items="results"
            :search-input.sync="searchText"
            cache-items
            clearable
            :label="label"
            auto-select-first
            autofocus
            item-text="slug"
            return-object
            :loading="loading"
            class="filterInput"
            required
    >
        <!--        <template v-slot:item="data">-->
        <!--            <slot>-->
        <!--                <div class="d-flex align-center">-->
        <!--                    <v-list-tile-content>-->
        <!--                        <v-list-tile-title v-html="data.item.model"></v-list-tile-title>-->
        <!--                        <v-list-tile-sub-title-->
        <!--                                v-html="data.item.color"></v-list-tile-sub-title>-->
        <!--                    </v-list-tile-content>-->
        <!--                </div>-->
        <!--            </slot>-->
        <!--        </template>-->
    </v-autocomplete>
</template>
<script>
    import Api from '@/utils/api.js'

    const api = new Api();
    export default {
        name: 'ManufacturingProductVariantAutolookup',
        data() {
            return {
                searchText: '',
                loading: false,
                results: [],
                errors: [],
            }
        },
        props: {
            value: {},
            url: {
                type: String,
                default: ''
            },
            searchKwarg: {
                type: String,
                default: 'slug'
            },
            label: {
                type: String,
                default: ''
            }
        },
        created() {
            // eslint-disable-next-line
            this.debouncedSearch = _.debounce(function () {
                this.doSearch(this.searchText)
            }, 150);
        },
        watch: {
            searchText() {
                this.debouncedSearch()
            }
        },
        methods: {
            doSearch(v) {
                this.loading = true;
                const params = {[this.searchKwarg]: v}
                api.get(this.url, params)
                    .then(r => {
                        this.results = r.data;
                    });
                this.loading = false
            },
            reset() {
                this.searchText = '';
                this.results = [];
                this.loading = false;
            },
            reset_errors() {
                this.errors = [];
            }
        }
    }
</script>
<style scoped lang="less">
</style>