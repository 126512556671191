<template>
  <form method="POST" enctype="multipart/form-data" :action="url" ref="formData"
        @submit.prevent=""
  >
    <v-card>
      <v-card-title>
        <v-btn v-if="openUrlLink" outlined text :href="formUrl" target="_blank">{{ $translate('Open Task') }}
        </v-btn>
        <span v-if="titleText !== ''">{{ titleText }}</span>
      </v-card-title>
      <v-card-text :style="loadingFormStyle">
        <v-progress-circular
            :size="50"
            indeterminate
            v-if="loadingForm"
        ></v-progress-circular>
        <div v-html="form" ref="formBody"></div>
      </v-card-text>
      <v-card-actions v-if="hasActions">
        <v-spacer></v-spacer>
        <v-btn
            v-if="submitCancelBtn"
            color="primary" text
            :loading="updating"
            :disabled="updating"
            @click="$emit('close')"
        >{{ $translate('Close') }}
        </v-btn>
        <v-btn
            v-if="submitContinueBtn"
            color="primary" text
            :loading="updating"
            :disabled="updating"
            name="_continue"
            value="_continue"
            type="submit"
            @click="submitContinue"
        >{{ $translate('Done and continue on this process') }}
        </v-btn>
        <v-btn
            color="primary" text
            :loading="updating"
            :disabled="updating || alreadyDone"
            name="_done"
            value="_done"
            type="submit"
            @click="submitDone"
        >{{ submitDoneBtnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </form>
</template>
<script>
import {initForms} from '../utils/materialize.forms';
import Api from '@/utils/api.js'

const api = new Api();

export default {
  name: 'DjangoForm',
  data() {
    return {
      updating: false,
      form: '',
      loadingForm: false,
      alreadyDone: false,
    }
  },
  props: {
    formUrl: {
      type: String,
      default: ''
    },
    openUrlLink: {
      type: Boolean,
      default: false
    },
    formOnly: {
      type: Boolean,
      default: false
    },
    refreshManually: {
      type: Boolean,
      default: false
    },
    submitContinueBtn: {
      type: Boolean,
      default: false
    },
    submitCancelBtn: {
      type: Boolean,
      default: true
    },
    hasActions: {
      type: Boolean,
      default: true
    },
    flatCard: {
      type: Boolean,
      default: false
    },
    submitDoneBtnText: {
      type: String,
      default: function () {
        return this.$translate('Save')
      }
    },
    titleText: {
      type: String,
      default: ''
    }
  },
  computed: {
    url() {
      if (this.formOnly) {
        let url = new URL(this.formUrl, window.location.origin);
        url.searchParams.append('formOnly', true);
        return url;
      }
      return this.formUrl;
    },
    loadingFormStyle() {
      return this.loadingForm ? {'text-align': 'center'} : {}
    }
  },
  mounted() {
    if (this.formUrl !== '' && !this.refreshManually) {
      this.refresh()
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    formUrl(newUrl, oldUrl) {
      if (newUrl !== '' && !this.refreshManually) {
        this.refresh()
      }
    }
  },
  methods: {
    async submitDone() {
      this.updating = true;
      let success = await this._submit('_done');
      this.updating = false;
      if (success) this.$emit('done');
    },
    async submitContinue() {
      this.updating = true;
      await this._submit('_continue');
      this.updating = false;
      this.$emit('update');
    },
    async _submit(type) {
      const formData = new FormData(this.$refs.formData);
      formData.append('formOnly', true);
      formData.append(type, type);
      let r = await api.post(this.url, formData);
      if (r.headers['form-valid'] === "False") {
        this.form = r.data;
        this.initialize();
        this.$emit('failure');
        this.alreadyDone = false;
        return false;
      }
      if (r.headers['already-done'] === "True") {
        this.form = r.data;
        this.initialize();
        this.alreadyDone = true;
        return false;
      }
      if ('next-task' in r.headers) {
        this.formUrl = r.headers['next-task'];
        this.initialize();
        return false;
      }
      this.$emit('success');
      this.alreadyDone = false;
      return true;

    },

    async refresh() {
      this.loadingForm = true;
      this.form = '';
      await api.get(this.url)
          .then(r => {
            this.form = r.data;
            if (r.headers['already-done'] === "True") {
              this.alreadyDone = true;
            }
            this.initialize();
          });
      this.loadingForm = false;
    },
    initialize() {
      this.$nextTick(() => {
        initForms(this.$refs.formBody, this.url);
      });
    }
  },
}
</script>

<style lang="less" scoped>
/deep/ .row {
  margin-top: 0px !important;
}


/deep/ .error {
  background-color: transparent !important
}
</style>