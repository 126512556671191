<template>
    <div id="vue-search-bar">

        <el-tooltip
                content="
                    f = Firma, sp = Sekaci Plan, p = Produkt, m = Model,
                    v = Obj. vo vyrobe, z = Zamestnanec, oi = Podla polozky objednavky
                "
                placement="bottom-end">
            <i class="el-icon-search" @click="openDialog()" id="searchIcon">
            </i>
        </el-tooltip>

        <el-dialog
                :modal="false"
                :visible.sync="dialogVisible"
                width="30%"
                append-to-body
                top="2px"
                :show-close="false"

        >
            <el-search-bar
                    v-model="product"
                    ref="searchBar"
            ></el-search-bar>
        </el-dialog>
    </div>
</template>

<script>
    import ElSearchBar from "./components/element/ElSearchBar";

    export default {
        name: 'SearchBar',
        components: {
            ElSearchBar
        },
        data() {
            return {
                dialogVisible: false,
                product: {},
            }
        },
        computed: {},
        methods: {
            openDialog() {
                this.dialogVisible = true;
                if (this.$refs.searchBar) this.$refs.searchBar.slug = ''
                setTimeout(() => {
                    this.$refs.searchBar.focus()
                }, 200)
            }
        }
    }
</script>

<style scoped lang="less">
    /deep/ .el-dialog__header {
        display: none;
    }

    /deep/ .el-dialog__body {
        padding: 0px 20px;
    }

    /deep/ #searchIcon {
        cursor: pointer;
    }
</style>