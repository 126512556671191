<template>
  <div class="django-vue-form">
    <v-dialog v-if="hasDialog" v-model="dialog" persistent max-width="70%" aspect-ratio="1" max-height="70%" eager>
      <template v-slot:activator="{ on }">
        <v-btn color="primary" small text outlined v-on="on" @click="openDialog" ma-0>
          {{ title }}
        </v-btn>
      </template>
      <DjangoForm
          ref="djangoForm"
          :formUrl="url"
          :openUrlLink="openUrlLink"
          :formOnly="formOnly"
          :hasActions="hasActions"
          :refreshManually="refreshManually"
          :flatCard="flatCard"
          :submitDoneBtnText="$translate('Done')"
          @done="success"
          @update="update"
          @close="dialog = false"
      />

    </v-dialog>
    <DjangoForm
        v-else
        ref="djangoForm"
        :formUrl="url"
        :openUrlLink="openUrlLink"
        :formOnly="formOnly"
        :hasActions="hasActions"
        :refreshManually="refreshManually"
        :flatCard="flatCard"
        :submitDoneBtnText="$translate('Done')"
        @done="success"
        @update="update"
        @close="dialog = false"
    />
  </div>
</template>

<script>
import DjangoForm from "../DjangoForm";

export default {
  name: "DjangoVueForm",
  elClass: 'django-vue-form',
  components: {DjangoForm},
  data() {
    return {
      dialog: false,
      hasDialog: true,
      openUrlLink: true,
      formOnly: true,
      refreshManually: true,
      hasActions: true,
      flatCard: false,
      url: '',
      title: '',
    }
  },
  methods: {
    openDialog() {
      this.$refs.djangoForm.refresh();
    },
    update() {
      // eslint-disable-next-line no-undef
      let datatable = $('#table').DataTable();
      if (datatable !== undefined) {
        datatable.draw(false); // false = keep paginnation / ordering
      }
    },
    success() {
      this.dialog = false;
      this.update();
    }
  },
  mounted() {
    let dataClass = this.$el.className + '-data';
    let dataEl = this.$el.parentElement.getElementsByClassName(dataClass)[0];
    this.url = dataEl.dataset.url;
    this.title = dataEl.dataset.title;
    if (dataEl.dataset.hasDialog === 'false') {
      this.hasDialog = false
    }
    if (dataEl.dataset.openUrlLink === 'false') {
      this.openUrlLink = false
    }
    if (dataEl.dataset.hasActions === 'false') {
      this.hasActions = false
    }
    if (dataEl.dataset.refreshManually === 'false') {
      this.refreshManually = false
    }
    if (dataEl.dataset.flatCard === 'true') {
      this.flatCard = true
    }
  },
}
</script>

<style scoped>
/deep/ button:focus {
  background-color: unset;
}


/deep/ .row {
  margin-top: 0px !important;
}

</style>